import React, { ChangeEventHandler, FocusEventHandler, KeyboardEventHandler, useState } from 'react';
import { Card, Input, Menu } from 'antd';
import { useHistory } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import moment from 'moment';
import truncate from 'lodash/truncate';
import { RcFile } from 'antd/lib/upload';
import { Key } from 'ts-key-enum';
import { SelectValue } from 'antd/lib/select';
import { Row, Col } from 'antd';

import './index.less';

import { botStageApi } from '../../../apis';
import {
  ScenarioCancellationMode,
  ScenarioContentFormat,
  ScenarioEditionReferenceModel,
  ScenarioInterruptionMode,
  UpdateScenarioOperationType,
} from '../../../../api';
import { alertsSelectorAdd } from '../../../recoil/alerts';
import { AlertTypes, ALLOWED_IMPORT_SCENARIO_FILE_TYPES } from '../../../constants';
import { downloadNamedFile } from '../../../utils/fileUtil';
import SbContextMenu from '../common/SbContextMenu';
import SbModal from '../common/SbModal';
import SbButton from '../common/SbButton';
import SbSwitch from '../common/SbSwitch';
import SbScenarioModeTag from '../SbScenarioModeTag';
import SbSelect from '../common/SbSelect';
import SbTypography from '../common/SbTypography';
import SbPanel from '../common/SbPanel';
import SbIcon from '../common/SbIcon';
import { SUBSTITUTE_DESCRIPTION, INTERRUPTING_DESCRIPTION } from '../../const';
import SbUpload from '../common/SbUpload';

interface ScenarioSettings {
  interruption: ScenarioInterruptionMode;
  cancellation: ScenarioCancellationMode;
}

enum ScenarioMode {
  Substitute = 'Substitute',
  Interrupting = 'Interrupting',
  Common = 'Common',
}

const ScenarioModeOptions = [
  { value: ScenarioMode.Substitute, label: 'Замещающий' },
  { value: ScenarioMode.Interrupting, label: 'Сквозной' },
  { value: ScenarioMode.Common, label: 'Обычный' },
];

const getScenarioMode = (settings: ScenarioSettings) => {
  if (
    settings.cancellation === ScenarioCancellationMode.CancelAll &&
    settings.interruption === ScenarioInterruptionMode.InterruptActive
  ) {
    return ScenarioMode.Substitute;
  }

  if (settings.interruption === ScenarioInterruptionMode.InterruptActive) {
    return ScenarioMode.Interrupting;
  }

  return ScenarioMode.Common;
};

const getScenarioSettings = (scenarioMode: ScenarioMode): ScenarioSettings => {
  switch (scenarioMode) {
    case ScenarioMode.Substitute:
      return {
        interruption: ScenarioInterruptionMode.InterruptActive,
        cancellation: ScenarioCancellationMode.CancelAll,
      };
    case ScenarioMode.Interrupting:
      return {
        interruption: ScenarioInterruptionMode.InterruptActive,
        cancellation: ScenarioCancellationMode.Disabled,
      };
    case ScenarioMode.Common:
    default:
      return {
        interruption: ScenarioInterruptionMode.Disabled,
        cancellation: ScenarioCancellationMode.Disabled,
      };
  }
};

interface ISbScenarioCardProps {
  botEntryId: string;
  botStageId: string;
  scenario: ScenarioEditionReferenceModel;
  onDataChanged: () => Promise<void>;
}

const SbScenarioCard: React.FC<ISbScenarioCardProps> = ({
  botEntryId,
  botStageId,
  scenario,
  onDataChanged = () => Promise.resolve(),
}) => {
  const { push } = useHistory();
  const addAlert = useSetRecoilState(alertsSelectorAdd);

  const [toggling, setToggling] = useState(false);
  const [title, setTitle] = useState(scenario.name);
  const [titleIsEditing, setTitleIsEditing] = useState(false);
  const [isDeleteConfirmationModalVisible, setIsDeleteConfirmationModalVisible] = useState(false);
  const [isSettingsModalVisible, setIsSettingsModalVisible] = useState(false);
  const [isDisableConfirmationModalVisible, setIsDisableConfirmationModalVisible] = useState(false);
  const [scenarioMode, setScenarioMode] = useState(
    getScenarioMode({ interruption: scenario.interruption, cancellation: scenario.cancellation })
  );

  const [scenarioUsagesSearching, setScenarioUsagesSearching] = useState(false);
  const [scenarioUsages, setScenarioUsages] = useState<ScenarioEditionReferenceModel[]>([]);

  const loading = scenarioUsagesSearching;
  const goToScenario = () => push(`/simple-bots/${botEntryId}/scenario/${scenario.scenarioStructureId}`);

  const onTitleInputBlur = async () => {
    setTitleIsEditing(false);

    if (!title) {
      setTitle(scenario.name);
      return;
    }

    if (title === scenario.name) return;

    try {
      await botStageApi.updateScenario(botStageId, {
        scenarioStructureId: scenario.scenarioStructureId,
        operationType: UpdateScenarioOperationType.Rename,
        name: title,
      });
      await onDataChanged();
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при изменении названия сценария',
        error: e,
      });
    }
  };

  const onTitleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => setTitle(e.target.value);

  const onTitleInputFocus: FocusEventHandler<HTMLInputElement> = (e) => e.target.select();

  const onTitleInputKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === Key.Enter) {
      onTitleInputBlur().finally();
    } else if (e.key === Key.Escape) {
      setTitle(scenario.name);
      setTitleIsEditing(false);
    }
  };

  const renderModificationDate = () => {
    const dateFormat = 'DD.MM.YYYY в HH:mm';

    // TODO: добавить вариант "Добавлен", когда сценарий добавлен из шаблона и пока не изменялся
    if (scenario.createdOn === scenario.modifiedOn) {
      return `Создан ${moment(scenario.createdOn).format(dateFormat)}`;
    }

    return `Изменен ${moment(scenario.modifiedOn).format(dateFormat)}`;
  };

  const toggleScenario = async () => {
    setToggling(true);
    try {
      await botStageApi.updateScenario(botStageId, {
        scenarioStructureId: scenario.scenarioStructureId,
        operationType: UpdateScenarioOperationType.EnableDisable,
        enabled: !scenario.enabled,
      });
      await onDataChanged();
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при включении/выключении сценария',
        error: e,
      });
    }
    setToggling(false);
  };

  const onOpenScenario = () => goToScenario();

  const searchScenarioUsages = async () => {
    setScenarioUsagesSearching(true);
    try {
      const response = await botStageApi.getScenarioUsages(botStageId, scenario.scenarioStructureId);
      setScenarioUsages(response.data.scenarios);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при валидации бота',
        error: e,
      });
    }
    setScenarioUsagesSearching(false);
  };

  const onToggleScenario = async () => {
    if (scenario.enabled) {
      await searchScenarioUsages();
      setIsDisableConfirmationModalVisible(true);
      return;
    }

    await toggleScenario();
  };

  const onConfirmDisabling = async () => {
    setIsDisableConfirmationModalVisible(false);
    await toggleScenario();
  };

  const onChangeScenarioSettings = () => setIsSettingsModalVisible(true);

  const onSaveScenarioSettings = async () => {
    if (scenarioMode === getScenarioMode({ interruption: scenario.interruption, cancellation: scenario.cancellation }))
      return;

    setIsSettingsModalVisible(false);
    try {
      await botStageApi.updateScenario(botStageId, {
        scenarioStructureId: scenario.scenarioStructureId,
        operationType: UpdateScenarioOperationType.ChangeSettings,
        ...getScenarioSettings(scenarioMode),
      });
      await onDataChanged();
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при изменении настроек сценария',
        error: e,
      });
    }
  };

  const onDeleteScenario = async () => {
    await searchScenarioUsages();
    setIsDeleteConfirmationModalVisible(true);
  };

  const onConfirmDeletion = async () => {
    setIsDeleteConfirmationModalVisible(false);
    try {
      await botStageApi.deleteScenario(botStageId, scenario.scenarioStructureId);
      await onDataChanged();
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при удалении сценария',
        error: e,
      });
    }
  };

  const onDuplicateScenario = async () => {
    try {
      await botStageApi.duplicateScenario(botStageId, {
        scenarioStructureId: scenario.scenarioStructureId,
      });
      await onDataChanged();
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при дублировании сценария',
        error: e,
      });
    }
  };

  const onRenameScenario = () => setTitleIsEditing(true);

  const onExportScenario = async () => {
    try {
      const response = await botStageApi.exportScenario(
        botStageId,
        scenario.scenarioStructureId,
        ScenarioContentFormat.Json,
        { responseType: 'blob' }
      );
      downloadNamedFile(response);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при экспорте сценария',
        error: e,
      });
    }
  };

  const onImportScenarioFileUpload = async (file: RcFile, base64Content: string) => {
    try {
      await botStageApi.updateScenario(botStageId, {
        scenarioStructureId: scenario.scenarioStructureId,
        operationType: UpdateScenarioOperationType.Import,
        scenarioFile: {
          fileName: file.name,
          mimeType: file.type,
          content: base64Content,
        },
      });
      goToScenario();
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при импорте сценария',
        error: e,
        description:
          e.response?.data?.title === 'InvalidCast'
            ? 'Загружаемый файл не является файлом описания сценария'
            : undefined,
      });
    }
  };

  const onCardClick = () => goToScenario();

  const onDeleteConfirmationModalClose = () => setIsDeleteConfirmationModalVisible(false);

  const onDisableConfirmationModalClose = () => setIsDisableConfirmationModalVisible(false);

  const onSettingsModalClose = () => {
    setScenarioMode(getScenarioMode({ interruption: scenario.interruption, cancellation: scenario.cancellation }));
    setIsSettingsModalVisible(false);
  };

  const onScenarioModeChange = (value: SelectValue) => setScenarioMode(value as ScenarioMode);

  const contextMenuContent = (
    <Menu>
      <Menu.Item key="open" onClick={onOpenScenario}>
        Открыть сценарий
      </Menu.Item>
      <Menu.Item key="enable-disable" onClick={onToggleScenario}>
        {scenario.enabled ? 'Выключить' : 'Включить'}
      </Menu.Item>
      <Menu.Item key="duplicate" onClick={onDuplicateScenario}>
        Дублировать
      </Menu.Item>
      <Menu.Item key="rename" onClick={onRenameScenario}>
        Переименовать
      </Menu.Item>
      <Menu.Item key="export" onClick={onExportScenario}>
        Экспортировать
      </Menu.Item>
      <Menu.Item key="import">
        <SbUpload
          accept={ALLOWED_IMPORT_SCENARIO_FILE_TYPES.join(',')}
          className="sb-scenario-list-card__import-upload"
          onFileUpload={onImportScenarioFileUpload}
        >
          Импортировать
        </SbUpload>
      </Menu.Item>
      <Menu.Item key="settings" onClick={onChangeScenarioSettings}>
        Настройки
      </Menu.Item>
      <Menu.Item key="delete" onClick={onDeleteScenario}>
        Удалить
      </Menu.Item>
    </Menu>
  );

  const cardClasses = ['sb-scenario-list-card'];
  if (titleIsEditing) {
    cardClasses.push('sb-scenario-list-card_unclickable');
  }
  if (loading) {
    cardClasses.push('sb-scenario-list-card_loading');
  }

  const maxTitleDisplayLength =
    scenario.interruption === ScenarioInterruptionMode.InterruptActive ||
    scenario.cancellation === ScenarioCancellationMode.CancelAll
      ? 30
      : 40;

  return (
    <React.Fragment>
      <Card hoverable className={cardClasses.join(' ')} onClick={onCardClick}>
        {loading && <SbIcon spin iconName="loading-four" />}
        <div className="sb-scenario-list-card__container">
          <div className="sb-scenario-list-card__top-row">
            <div className="sb-scenario-list-card__top-row__title">
              <h3>
                {titleIsEditing ? (
                  <Input
                    autoFocus
                    value={title}
                    onBlur={onTitleInputBlur}
                    onChange={onTitleInputChange}
                    onClick={(e) => e.stopPropagation()}
                    onFocus={onTitleInputFocus}
                    onKeyDown={onTitleInputKeyDown}
                  />
                ) : (
                  <span className="text-part" title={title.length > maxTitleDisplayLength ? title : undefined}>
                    {truncate(title, { length: maxTitleDisplayLength })}
                  </span>
                )}
                <div className="sb-scenario-list-card__top-row__title__tag-container">
                  <SbScenarioModeTag cancellation={scenario.cancellation} interruption={scenario.interruption} />
                </div>
              </h3>
            </div>
            <div className="sb-scenario-list-card__top-row__icon" role="none" onClick={(e) => e.stopPropagation()}>
              <SbContextMenu menuContent={contextMenuContent} />
            </div>
          </div>
          <div className="sb-scenario-list-card__second-row">{renderModificationDate()}</div>
          <div className="sb-scenario-list-card__bottom-row" role="none" onClick={(e) => e.stopPropagation()}>
            <SbSwitch
              checked={scenario.enabled}
              label={scenario.enabled ? 'Сценарий включен' : 'Сценарий выключен'}
              loading={toggling}
              onClick={onToggleScenario}
            />
          </div>
        </div>
      </Card>
      <SbModal
        footer={[
          <SbButton key="delete" sbSize="medium" sbType="primary" onClick={onConfirmDeletion}>
            Удалить
          </SbButton>,
          <SbButton key="cancel" sbSize="medium" sbType="secondary" onClick={onDeleteConfirmationModalClose}>
            Отмена
          </SbButton>,
        ]}
        sbSize="small"
        title="Подтвердите удаление сценария"
        visible={isDeleteConfirmationModalVisible}
        onCancel={onDeleteConfirmationModalClose}
        onOk={onConfirmDeletion}
      >
        {!!scenarioUsages.length && (
          <SbTypography>
            <p className="sb-typography__paragraph_lead">
              Удаление может привести к некорректной работе бота, т.к. сценарий используется в других сценариях:
              <ul>
                {scenarioUsages.map((s) => (
                  <li key={s.scenarioStructureId}>{s.name}</li>
                ))}
              </ul>
            </p>
          </SbTypography>
        )}
        <div>
          Вы действительно хотите удалить сценарий <b>{scenario.name}</b>?
        </div>
      </SbModal>
      <SbModal
        footer={[
          <SbButton key="delete" sbSize="medium" sbType="primary" onClick={onSaveScenarioSettings}>
            Сохранить
          </SbButton>,
          <SbButton key="cancel" sbSize="medium" sbType="secondary" onClick={onSettingsModalClose}>
            Отмена
          </SbButton>,
        ]}
        sbSize="small"
        title="Настройки сценария"
        visible={isSettingsModalVisible}
        width={650}
        onCancel={onSettingsModalClose}
        onOk={onSaveScenarioSettings}
      >
        <Row gutter={[24, 24]}>
          <Col span={8}>
            <SbTypography>
              <h3>Тип сценария</h3>
              <SbSelect
                options={ScenarioModeOptions}
                sbSize="small"
                sbType="light"
                value={scenarioMode}
                onChange={onScenarioModeChange}
              />
            </SbTypography>
          </Col>
          <Col span={16}>
            <SbPanel sbType="help">
              <SbTypography>
                {scenarioMode === ScenarioMode.Substitute ? (
                  SUBSTITUTE_DESCRIPTION
                ) : scenarioMode === ScenarioMode.Interrupting ? (
                  INTERRUPTING_DESCRIPTION
                ) : (
                  <>
                    Этот тип сценария не может быть замещающим или сквозным по отношению к другим сценариям, которые в
                    данный момент выполняются.
                    <br />
                    &nbsp;
                  </>
                )}
              </SbTypography>
            </SbPanel>
          </Col>
        </Row>
      </SbModal>
      <SbModal
        footer={[
          <SbButton key="disable" sbSize="medium" sbType="primary" onClick={onConfirmDisabling}>
            Выключить
          </SbButton>,
          <SbButton key="cancel" sbSize="medium" sbType="secondary" onClick={onDisableConfirmationModalClose}>
            Отмена
          </SbButton>,
        ]}
        sbSize="small"
        title="Подтвердите выключение сценария"
        visible={isDisableConfirmationModalVisible}
        onCancel={onDisableConfirmationModalClose}
        onOk={onConfirmDisabling}
      >
        {!!scenarioUsages.length && (
          <SbTypography>
            <p className="sb-typography__paragraph_lead">
              Выключение может привести к некорректной работе бота, т.к. сценарий используется в других сценариях:
              <ul>
                {scenarioUsages.map((s) => (
                  <li key={s.scenarioStructureId}>{s.name}</li>
                ))}
              </ul>
            </p>
          </SbTypography>
        )}
        <div>
          Вы действительно хотите выключить сценарий <b>{scenario.name}</b>?
        </div>
      </SbModal>
    </React.Fragment>
  );
};

export default SbScenarioCard;
