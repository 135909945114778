import { Monaco } from '@monaco-editor/react';

import {
  BotSchema,
  DefaultScenarioSchema,
  IntentTriggerSchema,
  SystemIntentReferenceSchema,
  UserIntentReferenceSchema,
  VariableSchema,
  VariableType,
} from '../../../../../api';
import {
  instanceOfBotSchema,
  instanceOfDefaultScenarioSchema,
  instanceOfDefaultTriggerGroupSchema,
  instanceOfIntentTriggerSchema,
  instanceOfSystemIntentReferenceSchema,
  instanceOfUserIntentReferenceSchema,
} from '../../../../components/ScenarioEditor/utils';
import { onlyUnique } from '../../../../utils/stringUtil';

import requireDeclarations from './requireDeclarations';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import AXIOS_index from '!!raw-loader!axios/index.d.ts';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import TYPES_globals from '!!raw-loader!@types/node/globals.d.ts';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import BOTFRAMEWORKSCHEMA_index from '!!raw-loader!botframework-schema/lib/index.d.ts';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import BOTFRAMEWORKSCHEMA_activityInterfaces from '!!raw-loader!botframework-schema/lib/activityInterfaces.d.ts';

const extraLibs = [
  {
    filePath: 'ts:@types/node/globals.d.ts',
    getContent: () => TYPES_globals,
  },
  {
    filePath: 'ts:axios/index.d.ts',
    getContent: () => {
      let content = AXIOS_index;
      content = content.replaceAll('export interface', 'declare interface');
      content = content.replaceAll('export type', 'declare type');
      content = content.replace('declare const axios: AxiosStatic;', '');
      content = content.replace('export default axios;', '');
      return content;
    },
  },
  {
    filePath: 'ts:botframework-schema/lib/index.d.ts',
    getContent: () => {
      let content = BOTFRAMEWORKSCHEMA_index;
      content = content.replaceAll('export interface', 'declare interface');
      content = content.replaceAll('export declare', 'declare');
      content = content.replaceAll(/^export \*.*/gm, '');
      content = content.replaceAll(/^export {.*/gm, '');
      content = content.replaceAll(/^import {.*/gm, '');
      return content;
    },
  },
  {
    filePath: 'ts:botframework-schema/lib/activityInterfaces.d.ts',
    getContent: () => {
      let content = BOTFRAMEWORKSCHEMA_activityInterfaces;
      content = content.replaceAll('export interface', 'declare interface');
      content = content.replaceAll('export declare', 'declare');
      content = content.replaceAll(/^import {.*/gm, '');
      return content;
    },
  },
];

export const SCRIPT_LANGUAGE = 'typescript';

export const DEFINITIONS_URI = 'ts:filename/context.d.ts';

const VARIABLE_TYPES: Record<string, string> = {
  [VariableType.Boolean]: 'boolean',
  [VariableType.Number]: 'number',
  [VariableType.String]: 'string',
  [VariableType.DateTime]: 'DateTime[] & DateTimeRange[]',
  [VariableType.PersonName]: 'PersonName',
  [VariableType.ComplexObject]: 'ComplexObject',
  [VariableType.SessionProfile]: 'SessionProfile',
  [VariableType.File]: 'Attachment | Attachment[]',
  [VariableType.PersonContacts]: 'PersonContacts',
};

const getScenarioIntentKeys = (scenarioStructure: DefaultScenarioSchema): string[] => {
  if (!instanceOfDefaultTriggerGroupSchema(scenarioStructure.triggerGroup)) {
    return [];
  }

  const intentReferences = scenarioStructure.triggerGroup.triggers
    .filter((t) => instanceOfIntentTriggerSchema(t))
    .map((t) => (t as IntentTriggerSchema).intentReference);

  return intentReferences
    .map((r) => {
      if (instanceOfSystemIntentReferenceSchema(r)) {
        const reference = r as SystemIntentReferenceSchema;
        return `${reference.groupCode}__${reference.intentCode}`;
      }
      if (instanceOfUserIntentReferenceSchema(r)) {
        return (r as UserIntentReferenceSchema).intentId;
      }
      return null;
    })
    .filter((id) => id)
    .map((id) => id as string)
    .filter(onlyUnique);
};

const getBotIntentKeys = (bot: BotSchema): string[] => {
  const intentKeys = [] as string[];

  bot.scenarios.forEach((s) => {
    if (instanceOfDefaultScenarioSchema(s)) {
      intentKeys.push(...getScenarioIntentKeys(s));
    }
  });

  return intentKeys.filter(onlyUnique);
};

const getBotVariables = (bot: BotSchema): VariableSchema[] => {
  const variables = [...bot.variables];

  bot.scenarios.forEach((s) => {
    if (instanceOfDefaultScenarioSchema(s)) {
      variables.push(...s.variables);
    }
  });

  return variables;
};

export const getTypeDefinitions = (schema?: BotSchema | DefaultScenarioSchema): string => {
  if (!schema) {
    return '';
  }

  const variables = instanceOfBotSchema(schema) ? getBotVariables(schema) : schema.variables;
  const intentKeys = instanceOfBotSchema(schema) ? getBotIntentKeys(schema) : getScenarioIntentKeys(schema);

  const lines = [];

  lines.push('declare class ObjectBase {');
  lines.push('  toString(): string;');
  lines.push('  toLocaleString(): string;');
  lines.push('}');

  lines.push('declare class DateTime extends ObjectBase {');
  lines.push('  Timex?: string;');
  lines.push('  Value?: string;');
  lines.push('}');

  lines.push('');
  lines.push('declare class DateTimeRange extends ObjectBase {');
  lines.push('  Timex?: string;');
  lines.push('  Start?: string;');
  lines.push('  End?: string;');
  lines.push('}');

  lines.push('');
  lines.push('declare class PersonName extends ObjectBase {');
  lines.push('  last?: string;');
  lines.push('  first?: string;');
  lines.push('  middle?: string;');
  lines.push('}');

  lines.push('');
  lines.push('declare class PersonContacts extends ObjectBase {');
  lines.push('  email?: string;');
  lines.push('  phone?: string;');
  lines.push('}');

  lines.push('');
  lines.push('declare class ComplexObject extends ObjectBase {');
  lines.push('  $text?: string;');
  lines.push('  $markdown?: string;');
  lines.push('  __name?: string;');
  lines.push('  [key: string]: any;');
  lines.push('}');

  lines.push('');
  lines.push('declare class SessionProfile extends ObjectBase {');
  lines.push('  authenticated: boolean;');
  lines.push('  fullName: PersonName;');
  lines.push('  contacts: PersonContacts;');
  lines.push('}');

  lines.push('');
  lines.push('declare enum ScriptLogLevel {');
  lines.push("  Log = 'LOG',");
  lines.push("  Error = 'ERROR',");
  lines.push("  Warn = 'WARN',");
  lines.push("  Debug = 'DEBUG',");
  lines.push("  Verbose = 'VERBOSE',");
  lines.push('}');

  lines.push('');
  lines.push('declare class ScriptLogEntry extends ObjectBase {');
  lines.push('  retry: number;');
  lines.push('  level: ScriptLogLevel;');
  lines.push('  message: string;');
  lines.push('  params: any[];');
  lines.push('}');

  lines.push('');
  lines.push('declare class ScriptLogger extends ObjectBase {');
  lines.push('  log: (message: string, ...params: any[]) => void;');
  lines.push('  error: (message: string, ...params: any[]) => void;');
  lines.push('  warn: (message: string, ...params: any[]) => void;');
  lines.push('  debug: (message: string, ...params: any[]) => void;');
  lines.push('  verbose: (message: string, ...params: any[]) => void;');
  lines.push('}');

  lines.push('');
  lines.push('declare class ScriptDictionary extends ObjectBase {');
  lines.push('  [key: string]: any;');
  lines.push('}');

  lines.push('');
  lines.push('declare class ScriptVariables extends ObjectBase {');
  for (const variableCode of variables.map((v) => v.code).filter(onlyUnique)) {
    const vars = variables.filter((v) => v.code === variableCode);
    const variableType = vars.map((v) => VARIABLE_TYPES[v.type] || 'string').join(' | ');
    lines.push(`  ${variableCode}: ${variableType};`);
  }
  for (const variableName of variables.map((v) => v.name).filter(onlyUnique)) {
    const vars = variables.filter((v) => v.name === variableName);
    const variableType = vars.map((v) => VARIABLE_TYPES[v.type] || 'string').join(' | ');
    lines.push(`  "${variableName}": ${variableType};`);
  }
  lines.push('}');

  lines.push(`type IntentKeyType = ${intentKeys.length ? intentKeys.map((id) => `"${id}"`).join(' | ') : 'string'};`);

  lines.push('');
  lines.push('declare class CardAction extends ObjectBase {');
  lines.push(`  text: string;`);
  lines.push(`  value: any;`);
  lines.push('}');

  lines.push('');
  lines.push('declare class ScriptResult extends ObjectBase {');
  lines.push(`  recognized: boolean;`);
  lines.push(`  promptText: string;`);
  lines.push(`  unrecognizedPromptText: string;`);
  lines.push(`  outputValue: any;`);
  lines.push(`  suggestedActions: string[] | CardAction[];`);
  lines.push(`  allowInterruptions: boolean;`);
  lines.push('}');

  lines.push('');
  lines.push('declare class ScriptInput extends ObjectBase {');
  lines.push('  environment: ScriptDictionary;');
  lines.push('  variables: ScriptVariables;');
  lines.push('  event: ScriptEvent;');
  lines.push('  extension: ScriptDictionary;');
  lines.push('  settings: ScriptExecutionBotSettings;');
  lines.push('}');

  lines.push('');
  lines.push('declare class ScriptExecutionBotSettings {');
  lines.push('  integrations: ScriptExecutionIntegrations');
  lines.push('  properties: [key: string]: unknown');
  lines.push('}');

  lines.push('');
  lines.push('declare class ScriptExecutionIntegrations {');
  lines.push('  elma365: ScriptExecutionELMA365IntegrationSettings');
  lines.push('}');

  lines.push('');
  lines.push('declare class ScriptExecutionELMA365IntegrationSettings {');
  lines.push('  apiUrl: string');
  lines.push('  xToken: string');
  lines.push('}');

  lines.push('');
  lines.push('declare class ScriptOutput extends ObjectBase {');
  lines.push('  variables: ScriptVariables;');
  lines.push('  result: ScriptResult;');
  lines.push('  extension: ScriptDictionary;');
  lines.push('  logger: ScriptLogger;');
  lines.push('  logs: ScriptLogEntry[];');
  lines.push('}');

  lines.push('');
  lines.push('declare class ScriptFileUtils extends ObjectBase {');
  lines.push('  uploadBotTemporaryFile: (fileName: string, contentType: string, file: Buffer) => Promise<string>;');
  lines.push('  getBotTemporaryFileUrl: (fileId: string) => string;');
  lines.push('}');

  lines.push('');
  lines.push('declare class ScriptStringUtils extends ObjectBase {');
  lines.push('  escapeMarkdown: (md: string) => string;');
  lines.push('}');

  lines.push('');
  lines.push('declare class ScriptCacheOptions {');
  lines.push('  ttl?: number;');
  lines.push('}');

  lines.push('');
  lines.push('declare class ScriptCache {');
  lines.push('  options?: ScriptCacheOptions;');
  lines.push('  getValue(key: string): Promise<unknown>;');
  lines.push('  setValue(key: string, value: unknown, options?: ScriptCacheOptions): Promise<void>;');
  lines.push('}');

  lines.push('');
  lines.push('declare class ScriptUtils extends ObjectBase {');
  lines.push('  files: ScriptFileUtils;');
  lines.push('  string: ScriptStringUtils;');
  lines.push('  cache: ScriptCache;');
  lines.push('}');

  lines.push('');
  lines.push('declare class ScriptContext extends ObjectBase {');
  lines.push('  input: ScriptInput;');
  lines.push('  output: ScriptOutput;');
  lines.push('  utils: ScriptUtils;');
  lines.push('}');

  lines.push('const context = new ScriptContext();');

  lines.push(
    'type EventType = "onInputDialogBegin" | "onInputDialogBeforeInterruption" | "onInputDialogValueRecognized" | "onIntentRecognized";'
  );

  lines.push(
    `type VariableCodeType = ${
      variables.length
        ? variables
            .map((v) => `"${v.code}"`)
            .filter(onlyUnique)
            .join(' | ')
        : 'string'
    };`
  );

  const botEventsDeclarations = `

  declare interface BotScriptEvent {
    eventName: EventType;
  }

  declare interface InputDialogEvent extends BotScriptEvent {
    eventName: "onInputDialogBegin" | "onInputDialogBeforeInterruption" | "onInputDialogValueRecognized";
    recognizerKind: string;
    variableCode?: VariableCodeType;
  }

  declare interface InputDialogBeginEvent extends InputDialogEvent {
    eventName: "onInputDialogBegin";
  }

  declare interface InputDialogBeforeInterruptionEvent extends InputDialogEvent {
    eventName: "onInputDialogBeforeInterruption";
    userInputText: string;
    recognizedValue?: object;
  }

  declare interface InputDialogValueRecognizedEvent extends InputDialogEvent {
    eventName: "onInputDialogValueRecognized";
    userInputText: string;
    recognizedValue?: object;
    activity?: Activity;
  }

  declare interface IntentRecognizedEvent extends BotScriptEvent {
    eventName: "onIntentRecognized";
    intentKey: string;
    recognizerResult: object;
    userInputText: string;
  }

  declare function onInputDialogBegin (
    variableCode: VariableCodeType | undefined,
    callback: (botEvent: InputDialogBeginEvent) => Promise<void>): void;

  declare function onInputDialogBeforeInterruption (
    variableCode: VariableCodeType | undefined,
    callback: (botEvent: InputDialogBeforeInterruptionEvent) => Promise<void>): void;

  declare function onInputDialogValueRecognized (
    variableCode: VariableCodeType | undefined,
    callback: (botEvent: InputDialogValueRecognizedEvent) => Promise<void>): void;

  declare function onIntentRecognized (
    intentKey: IntentKeyType | undefined,
    callback: (botEvent: IntentRecognizedEvent) => Promise<void>): void;
  `;

  return lines.join('\n') + requireDeclarations + botEventsDeclarations;
};

export const configureDefaultSettings = (monaco: Monaco): void => {
  const compilerOptions = monaco.languages.typescript.typescriptDefaults.getCompilerOptions();

  compilerOptions.target = monaco.languages.typescript.ScriptTarget.ESNext;
  compilerOptions.module = monaco.languages.typescript.ModuleKind.ESNext;

  monaco.languages.typescript.typescriptDefaults.setCompilerOptions(compilerOptions);

  monaco.languages.typescript.typescriptDefaults.setDiagnosticsOptions({
    diagnosticCodesToIgnore: [
      1375, // "'await' expressions are only allowed at the top level of a file when that file is a module, but this file has no imports or exports. Consider adding an empty 'export {}' to make this file a module."
      80005, // "'require' call may be converted to an import."
    ],
  });

  extraLibs.forEach((lib) => {
    const uri = monaco.Uri.parse(lib.filePath);
    const model = monaco.editor.getModel(uri);
    if (!model) {
      monaco.editor.createModel(lib.getContent(), SCRIPT_LANGUAGE, uri);
    }
  });
};
