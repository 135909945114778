import { configurationApi } from '../apis';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let config: any = null;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getConfig = async (): Promise<any> => {
  if (config !== null) {
    return config;
  }

  try {
    const response = await configurationApi.getClientConfig();
    // eslint-disable-next-line require-atomic-updates
    config = response.data;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Ошибка при загрузке конфигурации', e);
    throw e;
  }

  return config;
};

export { getConfig };
