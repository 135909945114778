import React, { useState } from 'react';
import { Typography } from 'antd';

import { ActivityModel } from '../../../../../../api';
import SbIcon from '../../../../components/common/SbIcon';
import SbPanel from '../../../../components/common/SbPanel';
import SbButton from '../../../../components/common/SbButton';
import ScriptEditor from '../../../../../components/ScenarioEditor/components/actions/ScriptAction/ScriptEditor';

const MAIN_CLASS = 'sb-dialogs-card__content__panel-container__info';
const FAILURE_STATUS = 'FAILURE';

interface ILogProps {
  level: string;
  message: string;
  retry: number;
  params: string[];
}

interface IScriptExecutionInfoProps {
  activity: ActivityModel;
}

const ScriptExecutionInfo: React.FC<IScriptExecutionInfoProps> = ({ activity }) => {
  const [editorVisible, setEditorVisible] = useState(false);
  const response = activity.value?.response;
  const status = response?.status;
  const scriptText = activity.value?.originalScriptContent;
  const hasVariables =
    !!Object.entries(response?.output?.variables?.conversation ?? []).length ||
    !!Object.entries(response?.output?.variables?.dialog ?? []).length ||
    !!Object.entries(response?.output?.variables?.user ?? []).length;

  const onShowScriptButtonClick = () => setEditorVisible(true);
  const onScriptEditorClose = () => setEditorVisible(false);

  return (
    <>
      <Typography.Title ellipsis level={4}>
        Подробнее о скрипте
      </Typography.Title>
      {status === FAILURE_STATUS && (
        <SbPanel sbType="alert">
          <div className={`${MAIN_CLASS}__secondary-info`}>{response?.traceback}</div>
          <div className={`${MAIN_CLASS}__text`}>{response?.message}</div>
        </SbPanel>
      )}
      <>
        <div className={`${MAIN_CLASS}__additional-title`}>
          <SbIcon iconName="timer" size={20} />
          <div>Время выполнения</div>
        </div>
        <div className={`${MAIN_CLASS}__border-frame`}>
          <div className={`${MAIN_CLASS}__border-frame__text`}>{activity.value?.duration}</div>
        </div>
        {hasVariables && (
          <>
            <div className={`${MAIN_CLASS}__additional-title`}>
              <SbIcon iconName="pencil" size={20} />
              <div>Переменные</div>
            </div>
            <div className={`${MAIN_CLASS}__log-details`}>
              {Object.entries(response?.output?.variables?.dialog).map(([key, value]) => (
                <>
                  <b>{key.replace('var_', '')}</b>
                  <pre>{JSON.stringify(value, null, 2)}</pre>
                </>
              ))}
              {Object.entries(response?.output?.variables?.conversation).map(([key, value]) => (
                <>
                  <b>{key.replace('var_', '')}</b>
                  <pre>{JSON.stringify(value, null, 2)}</pre>
                </>
              ))}
              {Object.entries(response?.output?.variables?.user).map(([key, value]) => (
                <>
                  <b>{key.replace('var_', '')}</b>
                  <pre>{JSON.stringify(value, null, 2)}</pre>
                </>
              ))}
            </div>
          </>
        )}
        {!!response?.output?.logs?.length && (
          <>
            <div className={`${MAIN_CLASS}__additional-title`}>
              <SbIcon iconName="code-one" size={20} />
              <div>Логи</div>
            </div>
            <div className={`${MAIN_CLASS}__log-details`}>
              {response?.output?.logs?.map((log: ILogProps) => (
                <>
                  <b>{`${log.level} `}</b>
                  {`Retry: ${log.retry}; Message: ${log.message}`}
                  <br />
                  {!!log.params.length && (
                    <>Params: {<pre>{log.params.map((param) => JSON.stringify(param, null, 2))}</pre>}</>
                  )}
                </>
              ))}
            </div>
          </>
        )}
        {scriptText && (
          <SbButton sbSize="big" sbType="secondary" onClick={onShowScriptButtonClick}>
            Открыть код скрипта
          </SbButton>
        )}
        <ScriptEditor readOnly content={scriptText} visible={editorVisible} onClose={onScriptEditorClose} />
      </>
    </>
  );
};

export default ScriptExecutionInfo;
